import { useJwt } from "@vueuse/integrations/useJwt"

export const useSessionUserManager = () => {
  const authToken = useStatefulCookie("authToken", { maxAge: 86400 })
  const refreshToken = useStatefulCookie("refreshToken", {
    maxAge: 2592000
  })
  const commerceLayerToken = useStatefulCookie("cmlToken", {
    maxAge: 3600
  })

  const isTokenExpired = (token: string) => {
    if (!token) return true

    const { payload } = useJwt<AuthTokenPaylod>(token)
    if (!payload.value?.exp) return true

    const timestampInseconds = Math.floor(Date.now() / 1000)
    const isExpired = timestampInseconds > payload.value.exp
    return isExpired
  }

  const fetchAuthToken = async (refreshToken: string) => {
    const { authToken } = await $fetch(
      `/api/account/refreshToken/${refreshToken}`
    )
    return authToken
  }

  const fetchCommerceLayerToken = async (authToken: string) => {
    const { payload: authTokenPayload } = useJwt<AuthTokenPaylod>(authToken)
    const { jwtToken: commerceLayerToken } = await $fetch(
      `/api/account/jwtCommerclayer/${
        authTokenPayload.value.username ?? authTokenPayload.value.email
      }/${authToken}`
    )
    return commerceLayerToken
  }

  const refreshAuthToken = async (refreshToken: string) => {
    const newAuthToken = await fetchAuthToken(refreshToken)
    authToken.value = newAuthToken
    return newAuthToken
  }

  const refreshCommerceLayerToken = async (authToken: string) => {
    const newCommerceLayerToken = await fetchCommerceLayerToken(authToken)
    commerceLayerToken.value = newCommerceLayerToken
    return newCommerceLayerToken
  }

  const refreshTokens = async (refreshToken: string) => {
    const newAuthToken = await refreshAuthToken(refreshToken)
    const newCommerceLayerToken = await refreshCommerceLayerToken(newAuthToken)
    return {
      authToken: newAuthToken,
      commerceLayerToken: newCommerceLayerToken
    }
  }

  const resetTokens = () => {
    authToken.value = null
    refreshToken.value = null
    commerceLayerToken.value = null
  }

  return {
    authToken,
    refreshToken,
    commerceLayerToken,
    refreshAuthToken,
    refreshCommerceLayerToken,
    refreshTokens,
    resetTokens,
    isTokenExpired
  }
}

type AuthTokenPaylod = SocialAuthTokenPaylod | MailAuthTokenPaylod

type SocialAuthTokenPaylod = {
  sub: string
  email_verified: boolean
  iss: string
  "cognito:username": string
  origin_jti: string
  aud: string
  event_id: string
  token_use: string
  auth_time: number
  exp: number
  iat: number
  jti: string
  email: string
}

type MailAuthTokenPaylod = {
  sub: string
  email_verified: boolean
  iss: string
  "cognito:username": string
  origin_jti: string
  aud: string
  identities: {
    userId: string
    providerName: string
    providerType: string
    issuer: null
    primary: string
    dateCreated: string
  }[]
  event_id: string
  token_use: string
  auth_time: number
  name: string
  exp: number
  iat: number
  family_name: string
  jti: string
  email: string
}
