import { AddressCreate, AddressUpdate } from "@commercelayer/sdk"
import { storeToRefs } from "pinia"
import { NotificationAlertProps } from "~/components/NotificationAlert.props"
import { useUserStore } from "~/stores/user"
import { setAccountDefaultAddress } from "~/utils/user/setAccountDefaultAddress"
import { splitFullName } from "~/utils/user/splitFullName"

export type UserAddress = {
  fullName: string
  fiscalCode: string
  phone: string
  line1: string
  notes: string
  city: string
  province: string
  zipCode: string
  isDefaultAddress: boolean
  addressId: string
  customerAddressId: string
  wholeAddress?: string
}

export default () => {
  const { $cl } = useNuxtApp()
  const userStore = useUserStore()
  const { userId } = storeToRefs(userStore)
  const { addNotification } = useNotification()
  const { t } = useI18n()

  const mapAddress = async (addressId: string) => {
    const dataUser = await $cl.customers.retrieve(userId.value)
    const info = await $cl.customer_addresses.address(addressId)

    if (info) {
      const addressObject: UserAddress = {
        customerAddressId: info.id,
        addressId: addressId,
        line1: info.line_1,
        city: info.city ?? "",
        fiscalCode: info.billing_info?.trim() ?? "",
        fullName: info.full_name ?? "",
        notes: info.notes ?? "",
        phone: info.phone ?? "",
        province: info.state_code ?? "",
        zipCode: info.zip_code || "",
        isDefaultAddress:
          dataUser?.metadata?.defaultAddress === addressId || false,
        wholeAddress: `${info.line_1} ${info.city} ${info.state_code} ${info.zip_code}`
      }
      return addressObject
    }
  }

  const currentAddresses = async () =>
    await $cl.customers
      .customer_addresses(userId.value)
      .then(async (response) => {
        const data = response?.map(
          async (address) => await mapAddress(address.id)
        )

        return Promise.all(data).then((responses) => responses)
      })
      .catch(() => [])

  const getAllUserAddresses = async () => {
    const addresses = await currentAddresses()
    const isAnyAddressDefault = addresses.some(
      (address) => address?.isDefaultAddress
    )
    // As per TF-2528, if there are no default addresses, set the first one as default
    if (!isAnyAddressDefault) {
      const firstAddress = addresses[0]
      await setAccountDefaultAddress(firstAddress?.addressId, userId.value)
    }

    const newAddresses = await currentAddresses()
    const defaultAddressFirst = newAddresses.sort(
      (a, b) => b?.isDefaultAddress as any
    )
    return defaultAddressFirst as UserAddress[]
  }

  const getUserAddress = async (id: string) => await mapAddress(id)

  const addAddress = async (userAddress: UserAddress) => {
    const allAdresses = await getAllUserAddresses()

    const { firstName, lastName } = splitFullName(userAddress.fullName)

    const objectAddress: AddressCreate = {
      first_name: firstName,
      last_name: lastName,
      billing_info: userAddress.fiscalCode?.trim(),
      line_1: userAddress.line1,
      city: userAddress.city,
      zip_code: userAddress.zipCode,
      state_code: userAddress.province,
      country_code: "IT",
      phone: sanitizePhoneNumber(userAddress.phone),
      notes: userAddress.notes,
      metadata: {}
    }

    const createAddress = await $cl.addresses.create(objectAddress)
    if (createAddress) {
      const createAssociation = await $cl.customer_addresses.create({
        address: { id: createAddress.id, type: "addresses" },
        customer: { id: userId.value, type: "customers" }
      })

      if (createAssociation) {
        if (
          userAddress.isDefaultAddress ||
          allAdresses.length === 0 // As per TF-2528, if the user has no addresses, the first one will be set as default
        ) {
          await setAccountDefaultAddress(createAssociation.id, userId.value)
        }

        const dataUser = await $cl.customers.retrieve(userId.value)
        await userStore.updateUserData(dataUser)

        const notification: NotificationAlertProps = {
          id: Math.random(),
          type: "success",
          notificationAlertText: t("myAccount.newAddress.successSave"),
          isWithCloseIcon: true
        }
        addNotification(notification)
      }
    }
  }

  const editAddress = async (userAddress: UserAddress) => {
    const { firstName, lastName } = splitFullName(userAddress.fullName)
    const infoAddress = await $cl.customer_addresses.address(
      userAddress.addressId
    )

    const objectAddress: AddressUpdate = {
      id: infoAddress.id,
      first_name: firstName,
      last_name: lastName,
      billing_info: userAddress.fiscalCode?.trim(),
      line_1: userAddress.line1,
      city: userAddress.city,
      zip_code: userAddress.zipCode,
      state_code: userAddress.province,
      country_code: "IT",
      phone: sanitizePhoneNumber(userAddress.phone),
      notes: userAddress.notes
    }

    const updateAddress = await $cl.addresses.update(objectAddress)
    if (updateAddress) {
      const user = await $cl.customers.retrieve(userId.value)

      if (userAddress.isDefaultAddress) {
        await setAccountDefaultAddress(userAddress.addressId, userId.value)
      }
      // Only remove the default address if the user's address WAS DEFAULT (so from true -> to false)
      else if (
        userAddress.customerAddressId === user.metadata?.defaultAddress
      ) {
        await setAccountDefaultAddress("", userId.value)
      }

      const dataUser = await $cl.customers.retrieve(userId.value)
      userStore.updateUserData(dataUser)

      const notification: NotificationAlertProps = {
        id: Math.random(),
        type: "success",
        notificationAlertText: t(
          "myAccount.editAddress.notificationSuccessUpdate"
        ),
        isWithCloseIcon: true
      }
      addNotification(notification)
    }
  }

  return {
    mapAddress,
    getAllUserAddresses,
    getUserAddress,
    addAddress,
    editAddress
  }
}
