<script setup lang="ts">
import { storeToRefs } from "pinia"
import { useUserStore } from "~/stores/user"
import { connect as connectImage } from "~/connectors/fragments/WrapperImage"
import { OverbookingProps } from "~/components/DialogsAndModals/Overbooking.props"
import { WrapperImageProps } from "~/components/Utils/WrapperImage.props"

// TODO: define where display the sw version

console.log(`

                 +++++++++
               +++++   ++++              ++++
              +++         +++            ++++
              ++           ++          +++++++++
              ++            ++         +++++++++
              ++            ++           ++++   
       +++++++++   +++++++++++++++++     ++++   
    +++++++++++++++++++++++++++++++++++  ++++   
   +++         +++          ++       ++++ ++++++  +++
  ++          ++            ++         ++  +++++  +++ 
  ++          ++            ++         +++ 
  ++          ++           +++         +++ 
  +++         ++          +++         +++ ######
   ++++       ++        ++++        ++++ #######
     +++++++++++++++++++++  ++++++++++   ####
              ++            ++         #########
              ++            ++         ########
              ++            ++           ####
              ++           ++            ####
               +++       ++++            ####
                +++++++++++              ####    ###
                   +++++                 ####    ###




  ########################   APP VERSION: ${
    useRuntimeConfig().public.CLIENT_VERSION
  }   #############################

`)

const { initCart, initCompare, soldOutCompareData, limitedCompareData } =
  useCart()
const {
  initSavedForLater,
  handleAutomaticSaveForLaterForAnonymous,
  anonymousSaveForLaterProductStorage
} = useSavedForLater()
const userStore = useUserStore()
const { userId, sessionChecked } = storeToRefs(userStore)
const isLogged = useIsLogged()

const { openModal, closeModal } = useModal()

const alreadyVisited: any = useStatefulCookie("already-visited", {
  default: () => "",
  maxAge: 5184000
})

const mounted = ref(false)
onMounted(() => {
  mounted.value = true
  alreadyVisited.value =
    // check if cookie has empty string value
    alreadyVisited.value === ""
      ? // if so assign variable with false value (user did not visit the website yet)
        false
      : // otherwise check if cookie has false value
      alreadyVisited.value === false
      ? // if so assign variable with true value
        true
      : // otherwise return variable value
        alreadyVisited.value
  // Sometimes the cookiebot banner disappears after Nuxt init
  if (process.client && window?.Cookiebot && !window.Cookiebot.hasResponse) {
    window.Cookiebot.show()
  }
})

watch(
  [sessionChecked, mounted, userId, anonymousSaveForLaterProductStorage],
  async ([sessionChecked, mounted, userId]) => {
    if (!sessionChecked || !mounted || (isLogged.value && !userId)) {
      return
    }
    await initCart()
    if (isLogged.value && process.client) {
      await initSavedForLater()
      await handleAutomaticSaveForLaterForAnonymous()
    }
    const routeName = useRoute().name
    if (routeName !== "checkout-id" && routeName !== "social-login") {
      await initCompare()
    }
  },
  {
    immediate: true
  }
)
/*
// Commented out as duplicate of row 82 watcher
watch(
  [sessionChecked, userId],
  async ([sessionChecked, userId]) => {
    if (!sessionChecked || (isLogged.value && !userId)) {
      return
    }
    await initCart()
  },
  {
    immediate: true
  }
)
  */

const overbookingData = computed(() => [
  ...limitedCompareData.value,
  ...soldOutCompareData.value
])

watch(
  () => overbookingData.value,
  (overbookingData, oldOverbookingData) => {
    if (
      overbookingData.length &&
      overbookingData.length !== oldOverbookingData.length
    ) {
      openModal("overbooked-cart-product")
    } else {
      closeModal("overbooked-cart-product")
    }
  }
)

const overbookingModal = computed((): OverbookingProps | undefined => {
  const oosProduct = overbookingData.value[0]
  if (oosProduct) {
    return {
      overbookedProduct: {
        id: oosProduct.id,
        productCode: oosProduct.sku_code!,
        productImage: connectImage(
          oosProduct.metadata?.product_image
        ) as WrapperImageProps,
        title: oosProduct.name!,
        price: oosProduct.unit_amount_float!,
        quantity: oosProduct.quantity,
        newQuantity: oosProduct.item.inventory!.quantity
      }
    }
  }
})
</script>

<template>
  <div>
    <GTMInit />
    <NuxtLoadingIndicator color="#f9bd23" />
    <DialogsAndModalsUtilsModal
      id="overbooked-cart-product"
      :fullscreen="true"
      :enable-on-click-outside="false"
    >
      <DialogsAndModalsOverbooking
        v-if="overbookingModal"
        v-bind="overbookingModal"
        @on-close-modal="closeModal('overbooked-cart-product')"
      ></DialogsAndModalsOverbooking>
    </DialogsAndModalsUtilsModal>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<style>

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/Poppins/Poppins-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Poppins";
  font-weight: 600;
  font-display: swap;
  src: url("/fonts/Poppins/Poppins-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  font-display: swap;
  src: url("/fonts/Poppins/Poppins-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/Poppins/Poppins-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "VT323";
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/VT323/VT323-Regular.woff2") format("woff2");
}

@media print {
  @font-face {
    font-family: "Poppins";
    font-weight: 700;
    font-display: block;
    src: url("/fonts/Poppins/Poppins-Bold.woff2") format("woff2");
  }

  @font-face {
    font-family: "Poppins";
    font-weight: 600;
    font-display: block;
    src: url("/fonts/Poppins/Poppins-SemiBold.woff2") format("woff2");
  }

  @font-face {
    font-family: "Poppins";
    font-weight: 500;
    font-display: block;
    src: url("/fonts/Poppins/Poppins-Medium.woff2") format("woff2");
  }

  @font-face {
    font-family: "Poppins";
    font-weight: 400;
    font-display: block;
    src: url("/fonts/Poppins/Poppins-Regular.woff2") format("woff2");
  }

  @font-face {
    font-family: "VT323";
    font-weight: 400;
    font-display: block;
    src: url("/fonts/VT323/VT323-Regular.woff2") format("woff2");
  }
}

</style>
