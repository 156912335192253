<script setup lang="ts">
import type { WrapperImageProps } from "./WrapperImage.props"
import { useFocalPoint } from "~/composables/useFocalPoint"
const props = defineProps<WrapperImageProps>()
const setFocalPoint: Ref<string | Record<string, string>> = ref("")

const { width } = useWindowSize()

watch(width, () => {
  if (props.activateFocalPoint && props.focalPoint) {
    const { focalPoint } = useFocalPoint(
      200,
      200,
      props.image.width,
      props.image.height
    )
    setFocalPoint.value = focalPoint.value
  }
})

const isSVG = computed(() => !!props.image?.url?.includes(".svg"))
const isImageWithAttributeProrityHigh = computed(() => {
  return (
    props.imgAttrs &&
    // @ts-ignore
    props.imgAttrs["fetchpriority"] &&
    // @ts-ignore
    props.imgAttrs["fetchpriority"] === "high"
  )
})

const imgAttrs = computed(() => {
  return {
    style: `${props.deleteWidthFullFromStyle ? "" : "width: 100%;"}${
      setFocalPoint.value
        ? ", object-position:" + setFocalPoint.value?.["object-position"]
        : ""
    }`,
    ...props.imgAttrs
  }
})
</script>

<template>
  <div class="wrapper-image">
    <NuxtImg
      v-if="isSVG"
      :src="image?.url"
      class="image-svg h-full w-full"
      :fit="fit"
      :height="image.height"
      :width="image.width"
      :alt="altText"
      :title="titleText"
      :sizes="sizes"
      provider="contentful"
      placeholder
      loading="lazy"
    />
    <NuxtPicture
      v-else
      format="webp"
      provider="contentful"
      :src="image?.url"
      :fit="fit"
      :height="image.height"
      :width="image.width"
      :alt="altText"
      :title="titleText"
      :sizes="sizes"
      :img-attrs="imgAttrs"
      placeholder
      :preload="preload"
      :loading="!isImageWithAttributeProrityHigh ? 'lazy' : undefined"
    />
  </div>
</template>
