import aa from "search-insights"
import { useUserStore } from "~/stores/user"
import Cookie from "js-cookie"
import { storeToRefs } from "pinia"

export const getUserId = () => {
  const userStore = useUserStore()
  const { userId } = storeToRefs(userStore)
  return Cookie.get("_ALGOLIA") ?? userId.value
}
export const initAlgolia = () => {
  const user = getUserId()
  aa("setUserToken", user)
}

export const algoliaEventClickProductAfterSearch = (
  index: any,
  eventName: string,
  objectIDs: string[],
  queryID: string,
  positions: number[]
) => {
  const user = getUserId()
  aa("clickedObjectIDsAfterSearch", {
    userToken: user,
    index: index.indexName,
    eventName,
    objectIDs,
    queryID,
    positions: positions ?? []
  })
}

export const algoliaEventClickProduct = (
  index: any,
  eventName: string,
  objectIDs: string[]
) => {
  const user = getUserId()
  aa("clickedObjectIDs", {
    userToken: user,
    index: index.indexName,
    eventName,
    objectIDs
  })
}

export const algoliaEventAddToCartAfterSearch = (
  index: any,
  eventName: string,
  queryID: string,
  objectIDs: string[]
) => {
  const user = getUserId()
  aa("convertedObjectIDsAfterSearch", {
    userToken: user,
    index: index.indexName,
    eventName,
    queryID,
    objectIDs
  })
}

export const algoliaEventAddToCart = (
  index: any,
  eventName: string,
  objectIDs: string[]
) => {
  const user = getUserId()
  aa("convertedObjectIDs", {
    userToken: user,
    index: index.indexName,
    eventName,
    objectIDs
  })
}

export const viewProductDetail = (
  index: any,
  eventName: string,
  objectIDs: string[]
) => {
  const user = getUserId()
  aa("viewedObjectIDs", {
    userToken: user,
    index: index.indexName,
    eventName,
    objectIDs
  })
}

export const hitsViewed = (
  insightsMethod: any,
  index: string,
  eventName: string,
  objectIDs: string[]
) => {
  const user = getUserId()
  aa(insightsMethod, {
    userToken: user,
    index,
    eventName,
    objectIDs
  })
}

export const excludeOutOfMarketRuleContext = "exclude_out_of_market"
export const excludeOutOfStockRuleContext = "exclude_out_of_stock"

export const buildExcludeFilter = (params?: {
  excludeOutOfMarket?: boolean
  excludeOutOfStock?: boolean
}) => {
  return {
    ruleContexts: [
      ...(!!params?.excludeOutOfMarket ? [excludeOutOfMarketRuleContext] : []),
      ...(!!params?.excludeOutOfStock ? [excludeOutOfStockRuleContext] : [])
    ]
  }
}
